import React from "react";
import SwiperCore, { Keyboard, Virtual, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Style.css";

import slide_1 from "../../images/slides/slide-01.png";
import slide_2 from "../../images/slides/slide-02.png";
import slide_3 from "../../images/slides/slide-03.png";
import { Container } from "react-bootstrap";

SwiperCore.use([Virtual, Navigation, Pagination]);

function Slider() {
  const items = [
    {
      id: 1,
      img: slide_1,
      url: "http://leem.net.br",
    },
    {
      id: 2,
      img: slide_2,
      url: "http://supernova.eng.br",
    },
    {
      id: 3,
      img: slide_3,
      url: "http://sistemagpsaude.com.br",
    },
  ];

  return (
    <React.Fragment>
      <Swiper
        breakpoints={{
          // when window width is >= 720px
          720: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 1024
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        }}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        virtual
        onAfterInit={(swiper) => swiper.slideNext()}
        keyboard={{
          enabled: true,
        }}
        modules={[Keyboard, Pagination, Navigation]}
      >
        {items.map(({ id, img, url }, index) => (
          <SwiperSlide
            key={id}
            virtualIndex={index}
            style={{
              background: `url(${url})`,
            }}
          >
            <div
              className="slide"
              style={{
                background: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
              }}
            >
              <div className="front-face"></div>
              <a className="button" href={url} target="_blank" rel="noreferrer">
                Ver detalhes
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </React.Fragment>
  );
}

function Portfolio() {
  return (
    <section className="portfolio">
      <Container>
        <h1 id="portfolio">Portfólio</h1>
        <Slider />
      </Container>
    </section>
  );
}

export default Portfolio;
