import React from "react";
import About from "./sections/About/Index";
import Footer from "./sections/Footer/Index";
import Header from "./sections/Header/Index";
import Portfolio from "./sections/Portfolio/Index";
import Timeline from "./sections/Timeline/Index";

const App = _ => (
    <React.Fragment>
        <Header />
        <About />
        <Portfolio />
        <Timeline />
        <Footer />
    </React.Fragment>
);

export default App;