import { Col, Container, Row } from "react-bootstrap";

import "./Style.css";

import ic_email from "../../images/ic_email.png";
import ic_linkedin from "../../images/ic_linkedin.png";

function Footer() {
  return (
    <section className="footer">
      <Container>
        <Row>
          <Col md={6} xl={3}>
            <h1 id="contact">Contate-me</h1>
            <p>Gostou do meu perfil? Entre em contato comigo para parcerias.</p>
          </Col>
          <Col md={6} xl={4} className="ms-auto">
            <address>
              <a href="mailto:danilsonvs04@gmail.com">
                <img src={ic_email} alt="Icone de envelop" />
                danilsonvs04@gmail.com
              </a>
              <a href="https://linkedin.com/in/danilsonvss/" target="_blank" rel="noreferrer">
                <img src={ic_linkedin} alt="Icone do linkedin" />
                Danilson Santos
              </a>
            </address>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Footer;
