import { Container } from "react-bootstrap";

import './Style.css';

function About() {
    return (
        <section className="about">
            <Container>
                <div className="content">
                    <h1 id="about">Sobre mim</h1>
                    <p>
                        Com mais de 10 anos de experiência, já tive a oportunidade de trabalhar com diversas tecnologias,
                        em projetos com nichos variados, desde sistemas para as áreas da saúde, rastreamento veicular
                        e info produtos.
                    </p>
                    <p>
                        Minha especialização principal é o desenvolvimento de aplicativos web, utilizando
                        os frameworks Laravel e ReactJs para projetos complexos. Ao longo do tempo, utilizei
                        diversas tecnologias incluindo PHP, Laravel, ReactJs, MySQL, PostgreSQL, Bootstrap, 
                        CSS 3 e HTML 5. Também tenho experiência relevante com desenvolvimento Android(Java/Kotlin).
                    </p>
                </div>
            </Container>
        </section>
    );
}

export default About;