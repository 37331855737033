import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import rolldown from '../../images/rolldown.png';

import './Style.css';

function Header() {
    return (
        <header>
            <Navbar sticky="top" expand="lg" variant="dark" className="header">
                <Container>
                    <Navbar.Brand href="#" className="brand">Danilson</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto" as="nav">
                            <Nav.Link href="#about">Sobre mim</Nav.Link>
                            <Nav.Link href="#portfolio">Portfólio</Nav.Link>
                            <Nav.Link href="#experience">Experiência</Nav.Link>
                            <Nav.Link href="#contact">Contate-me</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="objective">
                <Container>
                    <div className="text-center">
                        <h1>Danilson</h1>
                        <h2>
                            <span className="ornament">Desenvolvedor</span> <br />
                            PHP, Laravel, React.js...
                        </h2>
                    </div>
                    <div className="rolldown">
                        <button onClick={_ => window.location.href = "#about"}>
                            <img src={rolldown} alt="Rolar para baixo" />
                        </button>
                    </div>
                </Container>
            </div>
        </header>
    )
}

export default Header;