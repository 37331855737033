import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import WorkIcon from "@mui/icons-material/Work";

import "./Style.css";
import { Container } from "react-bootstrap";

const experiences = [
  {
    title: "Desenvolvedor fullstack",
    location: "Freelancer, Online",
    date: "2012 - presente",
    icon: <WorkIcon />,
    description:
      "Desenvolvimento fullstack PHP, Laravel, React e Android. Sites, sistemas e aplicativos.",
  },
  {
    title: "Desenvolvedor PHP",
    location: "Softdesign - Porto Alegre, RS",
    date: "2021 - presente",
    icon: <WorkIcon />,
    description:
      "Atualmente, trabalhando como desenvolvedor PHP/Laravel em um grande projeto de info produtos.",
  },
  {
    title: "Desenvolvedor PHP",
    location: "ICS - Goiânia, GO",
    date: "2019 - 2021",
    icon: <WorkIcon />,
    description:
      "Desenvolvedor fullstack(PHP, JavaScript, HTML e CSS) em sistema de gestão da saúde pública.",
  },
  {
    title: "Desenvolvedor PHP",
    location: "MAPCLOUD - Goiânia, GO",
    date: "2018 - 2019",
    icon: <WorkIcon />,
    description:
      "Desenvolvimento fullstack de aplicações web e Android para a área de rastreamento veicular.",
  },
];

function Timeline() {
  return (
    <section className="timeline text-center">
      <Container>
        <h1 id="experience">Experiência</h1>
        <VerticalTimeline>
          {experiences.map(
            ({ title, location, date, icon, description }, index) => (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#5025D1", color: "#fff" }}
                contentArrowStyle={{ borderRight: "7px solid #5025D1" }}
                date={date}
                iconStyle={{ background: "#5025D1", color: "#fff" }}
                icon={icon}
              >
                <h3 className="vertical-timeline-element-title">{title}</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  {location}
                </h4>
                <p>{description}</p>
              </VerticalTimelineElement>
            )
          )}
        </VerticalTimeline>
      </Container>
    </section>
  );
}

export default Timeline;
